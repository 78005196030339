.footer-main {
	background: $color-white;
	border-top: 1px solid rgba(#979797, 0.21);
	bottom: 0;
	padding: 50px 0;

	// position: fixed;
	width: 100%;
	z-index: 0;

	@include mq(tablet-down) {
		padding: 40px 0;
		position: relative;
	}


	#quoteMain & {
		position: static;
	}

	.sub-content {
		@extend %container;

		.sub-details {
			display: flex;
			margin-bottom: 40px;
			padding-bottom: 25px;
			position: relative;

			@include mq(tablet-down) {
				flex-direction: column;
				padding-bottom: 0;
				margin-bottom: 0;
			}


			.sub-contact-details {
				position: relative;

				@include mq(tablet-down) {
					padding-bottom: 40px;
					margin-bottom: 40px;
				}


				&:after {
					@include mq(tablet-down) {
						background: $color-primary;
						border-radius: 1.5px;
						bottom: 0;
						content: '';
						height: 3px;
						left: 0;
						position: absolute;
						width: 70px;
					}
				}

				h4 {
					font-size: $font-size-larger;
					margin-bottom: 5px;

					@include mq(tablet-down) {
						margin-bottom: 19px;
						padding-right: 50px;
					}
				}

				span {
					color: $color-headings;
					font-size: $font-size-larger;
					font-weight: $font-weight-light;
					display: inline-block;

					&:first-of-type {
						margin-right: 65px;
					}

					a {
						color: $color-black;
						font-weight: $font-weight-regular;

						&.sub-tel {
							pointer-events: none;

							@include mq(tablet-down) {
								pointer-events: all;
							}
						}
					}
				}
			}
		}

		ul.list-footer-links {
			@extend %list-default;

			align-items: center;
			display: flex;
			margin-left: auto;

			@include mq(tablet-down) {
				align-items: flex-start;
				margin-top: 40px;
				margin-left: 0;
			}


			@include mq(mobile) {
				flex-direction: column;
			}


			& > li {
				color: $color-text;
				font-size: 12px;
				font-weight: $font-weight-light;
				display: inline-block;
				margin-right: 30px;
				position: relative;

				@include mq(tablet-down) {
					font-size: 13px;
					margin-right: 15px;

					&:first-child {
						display: none;
					}
				}


				@include mq(mobile) {
					margin-bottom: 10px;
				}


				a, span {
					color: $color-text;
					cursor: pointer;
					font-weight: $font-weight-medium;
					transition: all .3s ease;

					&:hover {
						color: $color-primary;
					}

					svg {
						color: $color-primary;
						height: 14px;
						margin-left: 10px;
						margin-top: -6px;
						transform: rotate(-90deg);
						width: 9px;
					}
				}

				.list-terms {
					@extend %list-default;

					background: $color-white;
					border-radius: 12px;
					bottom: 55px;
					box-shadow: 13px 13px 50px -12px rgba(0, 0, 0, 0.48);
					opacity: 0;
					padding: 5px 15px;
					position: absolute;
					right: 0;
					width: 280px;
					z-index: -999;

					&.mod-active {
						opacity: 1;
						z-index: 99;
					}

					&:after {
						background: $color-white;
						bottom: -10px;
						content: '';
						display: block;
						height: 20px;
						position: absolute;
						right: 20px;
						transform: rotate(45deg);
						width: 20px;
					}

					& > li {
						&:not(:last-child) {
							border-bottom: 1px solid #D8D8D8;
						}

						a {
							font-size: $font-size-small;
							font-weight: $font-weight-semibold;
							display: block;
							padding: 10px 0;
							text-align: right;
						}
					}
				}
			}
		}

		.sub-bottom {
			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
			}


			.sub-socials {
				align-items: center;
				display: flex;

				@include mq(tablet-down) {
					display: block;
					width: 100%;
				}


				.btn {
					color: $color-black;
					font-size: 17px;
					font-weight: $font-weight-regular;
					height: 45px;
					margin-bottom: 0;
					margin-right: 40px;
					min-height: 45px;
					width: 185px;

					@include mq(tablet-down) {
						align-items: center;
						display: flex;
						margin-right: 0;
					}
				}

				.list-socials {
					@extend %list-default;

					display: flex;

					@include mq(tablet-down) {
						align-items: flex-start;
						max-width: 130px;
						margin-top: 30px;
					}


					& > li {
						display: flex;
						flex: 1;
						margin-right: 36px;

						&:last-child {
							margin-right: 0;
						}

						svg {
							color: $color-headings;
							height: 18px;
							transition: all .3s ease;
							max-width: 18px;

							&:hover {
								color: $color-primary;
								transform: translateY(-4px);
							}

							&.icon-youtube {
								max-width: 24px;
							}
						}
					}
				}
			}

			.sub-copyright {
				display: none;

				@include mq(tablet-down) {
					color: $color-text;
					font-size: $font-size-small;
					display: block;
					margin-top: 30px;
				}
			}
		}
	}
}
