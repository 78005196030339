#compliance {
	.section-hero {
		@extend %background-default;

		align-items: center;
		display: flex;
		height: 658px;
		position: relative;

		@media only screen and (max-width: 1330px) {
			height: auto;
			padding: 56px 0 0;
		}

		@include mq(tablet-down) {
			flex-direction: column;
		}


		.sub-content {
			@extend %container;

			display: flex;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);

			@media only screen and (max-width: 1330px) {
				left: auto;
				position: relative;
				top: auto;
				transform: none;
			}

			@include mq(tablet-down) {
				flex-direction: column;
				left: auto;
				position: relative;
				top: auto !important;
				transform: none !important;
			}


			h1 {
				font-size: $font-size-hero-small;

				@include mq(tablet-down) {
					font-size: $font-size-largest;
					margin-bottom: 20px;
				}
			}

			.sub-copy {
				flex: 1;
				max-width: 687px;
				width: 100%;

				@media only screen and (max-width: 1330px) {
					padding-right: 60px;
				}

				@include mq(tablet-down) {
					max-width: none;
					padding-right: 0;
				}


				p {
					color: $color-headings;
					font-size: 23px;
					font-weight: $font-weight-light;
					line-height: 40px;

					@include mq(tablet-down) {
						font-size: $font-size-small-med;
						line-height: 27px;
					}
				}
			}

			.sub-image {
				margin-left: auto;

				@include mq(tablet-down) {
					margin-left: 0;
					margin-top: 20px;

					img {
						max-width: 100%;
					}
				}
			}
		}

		.list-icons {
			@extend %list-default;

			margin-top: 60px;

			@include mq(tablet-down) {
				display: none;
			}


			&.mod-mobile {
				display: none;

				@include mq(tablet-down) {
					align-items: center;
					display: flex;
					height: 70px;
					justify-content: center;
					margin-top: 25px;
					width: 100%;

					& > li img {
						max-width: 35px;
					}
				}
			}

			& > li {
				display: inline-block;

				&:not(:last-child) {
					margin-right: 25px;
				}
			}
		}
	}

	.section-icomply-sections {
		background: #f7f7f7;
		padding: 50px 0 120px;

		@include mq(tablet-down) {
			padding: 40px 0 10px;
		}


		.sub-content {
			@extend %container;

			article {
				margin-bottom: 50px;

				@include mq(tablet-down) {
					margin-bottom: 30px;

					&:last-of-type {
						margin-bottom: 0;
					}
				}


				header {
					align-items: center;
					background-color: $color-white;
					padding: 40px 30px;
					margin-bottom: 6px;

					@include mq(tablet-down) {
						padding: 15px;
					}


					.sub-top {
						display: flex;
						margin-bottom: 25px;

						@media only screen and (max-width: 1330px) {
							align-items: center;
						}

						@include mq(tablet-down) {
							align-items: flex-start;
							display: flex;
							flex-direction: column;
						}


						.sub-small-logos {
							margin-left: auto;

							@include mq(tablet-down) {
								max-width: 100%;
								margin-top: 20px;
							}
						}

						h3 {
							align-items: center;
							display: flex;
							font-size: $font-size-h1;
							margin-bottom: 0;

							@include mq(tablet-down) {
								font-size: 19px;
							}


							img {
								margin-right: 15px;

								@include mq(tablet-down) {
									max-width: 30px;
								}
							}
						}
					}

					.sub-intro-copy {
						max-width: 860px;
						width: 100%;

						p {
							font-size: $font-size-med-large;
							line-height: 25px;

							@include mq(tablet-down) {
								font-size: $font-size-small-med;
							}


							&:only-child, &:last-child {
								margin-bottom: 0;
							}
						}
					}
				}

				.list-sub-sections {
					@extend %list-default;

					display: flex;
					flex-wrap: wrap;

					@include mq(tablet-down) {
						flex-direction: column;
					}


					& > li {
						background-color: $color-white;
						padding: 30px;
						margin-bottom: 6px;
						margin-right: 5px;
						width: calc(25% - 4px);

						@include mq(tablet-down) {
							margin-right: 0;
							padding: 15px;
							width: 100%;
						}


						&:last-child, &:nth-child(4n) {
							margin-right: 0;
						}

						h4 {
							color: $color-primary;
							font-weight: $font-weight-bold;
							margin-bottom: 13px;

							@include mq(tablet-down) {
								font-size: $font-size-med;
							}
						}

						.sub-copy {
							p {
								font-weight: $font-weight-medium;
								line-height: 24px;

								@include mq(tablet-down) {
									font-size: $font-size-small;
								}


								&:only-child, &:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}
