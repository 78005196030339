#blog-post {
	.section-hero {
		padding: 55px 0 70px;

		@include mq(mobile) {
			padding: 35px 0;
		}

		.wrap-hero {
			@extend %container;

			align-items: center;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			position: relative;

			@include mq(mobile) {
				flex-wrap: wrap;
			}

			.sub-copy {
				max-width: 430px;
				padding-right: 20px;

				@include mq(mobile) {
					width: 100%;
				}

				h1 {
					font-weight: $font-weight-medium;
					margin: 0 0 20px;

					@include mq(tablet-down) {
						font-size: $font-size-h3;
						margin-bottom: 15px;
					}
				}

				h3 {
					color: $color-text;
					font-size: $font-size-standard;
					font-weight: $font-weight-light;
					margin: 0 0 30px;

					@include mq(tablet-down) {
						font-size: $font-size-smaller;
					}
				}

				.sub-intro {
					p {
						color: $color-text;
						font-size: $font-size-med-large;
						font-weight: $font-weight-regular;
						line-height: 25px;
					}
				}

				.sub-social {
					cursor: pointer;

					ul.list-social {
						@extend %list-default;

						width: 25px;
						overflow: hidden;
						transition: all 0.3s ease;
						display: flex;

						@include mq(tablet-down) {
							display: none;
						}

						&:hover,
						&:focus {
							width: 100%;
						}

						li {
							margin: 0 22px 0 0;
							padding: 0;
							display: inline-block;

							.svg-icon {
								transition: color 0.2s ease;
								color: $color-black;

								&:hover {
									color: $color-primary;
								}
							}
						}
					}
				}
			}

			.sub-image {
				height: 415px;
				max-width: 620px;
				width: 100%;
				margin: 0 0 0 auto;

				@media screen and (max-width: 1255px) {
					height: 345px;
					max-width: 510px;
				}

				@include mq(tablet) {
					height: 270px;
					max-width: 400px;
				}

				@include mq(mobile) {
					height: 240px;
					margin-left: 0;
				}

				.slick-list {
					max-height: 415px;
					min-height: 415px;

					@media screen and (max-width: 1255px) {
						max-height: 345px;
						min-height: 345px;
					}

					@include mq(tablet) {
						max-height: 270px;
						min-height: 270px;
					}

					@include mq(mobile) {
						max-height: 240px;
						min-height: 240px;
					}

					.slick-track {
						min-height: 415px;

						@media screen and (max-width: 1255px) {
							max-height: 345px;
							min-height: 345px;
						}

						@include mq(tablet) {
							max-height: 270px;
							min-height: 270px;
						}

						@include mq(mobile) {
							max-height: 240px;
							min-height: 240px;
						}
					}
				}

				.carousel-image {
					max-height: 415px;
					min-height: 415px;
					position: relative;

					@media screen and (max-width: 1255px) {
						max-height: 345px;
						min-height: 345px;
					}

					@include mq(tablet) {
						max-height: 300px;
						min-height: 300px;
					}

					@include mq(mobile) {
						max-height: 240px;
						min-height: 240px;
					}

					& > img {
						@extend %image-cover;
					}
				}
			}
		}

		.slick-dots {
			display: flex;
			justify-content: center;
			margin-top: 20px;

			li {
				button {
					height: 12px;
					width: 12px;
				}

				&:not(.slick-active) button {
					background-color: $color-neutral-dark;
				}
			}
		}
	}

	.section-post {
		padding: 50px 0;

		@include mq(mobile) {
			padding: 10px 0 70px;
		}

		.sub-content {
			@extend %container, %clearfix;

			.sub-copy {
				max-width: 785px;

				p {
					font-size: $font-size-med;
					font-weight: $font-weight-light;
					line-height: 25px;
				}
			}
		}
	}

	.section-related.post-page {
		padding: 80px 0;
		background-color: $color-neutral;

		@include mq(tablet-down) {
			padding: 55px 0;
		}

		.wrap-related {
			@extend %container;
		}

		h2 {
			margin: 0 0 55px;
			font-size: $font-size-h1;

			@include mq(tablet-down) {
				font-size: $font-size-larger;
				margin-bottom: 40px;
			}
		}

		.sub-posts {
			display: flex;
			flex-wrap: wrap;
			margin-top: 15px;

			@include mq(tablet-down) {
				flex-direction: column;
				margin-top: 0;
			}

			.sub-post {
				background-color: $color-white;
				margin-bottom: 35px;
				margin-right: 35px;
				padding: 35px;
				position: relative;
				width: calc(33.333% - 24px);

				@include mq(tablet-down) {
					width: 100%;
				}

				&:nth-child(3n),
				&:last-of-type {
					margin-right: 0;
				}

				& > a {
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
					z-index: 99;

					&:hover ~ h3 {
						color: $color-primary;
					}

					&:hover ~ .sub-image-wrap .sub-image {
						transform: scale(1.03);
					}
				}

				h3 {
					font-weight: $font-weight-regular;
					font-size: 27px;
					line-height: 35px;
					padding-right: 55px;
					margin-bottom: 30px;
					transition: color 0.3s ease;

					@include mq(tablet-down) {
						padding-right: 0;
					}
				}

				h5 {
					color: $color-text-light;
					font-size: $font-size-small;
					font-weight: $font-weight-light;
					margin-bottom: 30px;
				}

				.sub-copy {
					padding-right: 40px;

					@include mq(tablet-down) {
						padding-right: 0;
					}

					p {
						color: $color-text;
						font-size: $font-size-small-med;
						font-weight: $font-weight-light;
						line-height: 24px;

						@include mq(tablet-down) {
							font-size: $font-size-standard;
						}
					}
				}

				.sub-image-wrap {
					background-color: #f7f7f7;
					height: 205px;
					overflow: hidden;

					@include mq(tablet-down) {
						height: 250px;
					}

					.sub-image {
						@extend %background-default;

						height: 100%;
						transition: all 0.3s ease;
						width: 100%;
					}
				}
			}
		}
	}
}
