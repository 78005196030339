.section-testimonials-block {
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 688px;
	position: relative;

	@include mq(tablet-down) {
		min-height: 420px;
	}


	h3 {
		font-size: $font-size-h1;
		color: $color-white;
		margin-bottom: 50px;

		@include mq(tablet-down) {
			font-size: $font-size-h3;
			margin-bottom: 25px;
		}
	}

	.list-testimonials-block {
		@extend %list-default;

		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		.slick-list, .slick-track, .slick-slide {
			height: 100%;
		}

		.slick-slide > div {
			height: 100%;

			li {
				align-items: center;
				display: flex !important;
				height: 100%;
				position: relative;

				.sub-content {
					@extend %container;
				}

				&:before {
					content: '';
					background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
					z-index: 9;
				}

				.sub-image {
					@extend %background-default;

					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
				}

				.sub-copy {
					max-width: 630px;
					width: 100%;
					z-index: 99;
				}
			}
		}

		li {
			margin: 0;
			padding: 0;
		}

		.sub-copy {
			position: relative;
			z-index: 99;

			blockquote {
				padding: 0;
				margin: 0;

				p {
					color: $color-white;
					font-size: 30px;
					font-weight: $font-weight-light;
					line-height: 42px;

					@include mq(tablet-down) {
						font-size: $font-size-med;
						line-height: 30px;
					}
				}
			}

			.sub-testimonial-name {
				color: $color-white;
				font-size: $font-size-large;
				font-weight: $font-weight-light;

				@include mq(tablet-down) {
					font-size: $font-size-med;
				}


				strong {
					font-weight: $font-weight-light;
				}
			}
		}

		.slick-dots {
			bottom: 70px;
			display: flex;
			justify-content: center;
			position: absolute;
			width: 100%;

			@include mq(tablet-down) {
				bottom: 15px;
			}


			li {
				@include mq(tablet-down) {
					margin-right: 20px;
				}


				button {
					background: $color-white;
					border: 1px solid $color-white;
					height: 11px;
					opacity: 1;
					width: 11px;
				}

				&.slick-active {
					button {
						background: transparent;
					}
				}
			}
		}
	}
}
