#quote, #quoteMain {
	.section-quote {
		padding: 100px 0;

		@include mq(tablet-down) {
			padding: 40px 0;
		}


		.btn {
			align-items: center;
			display: flex;
		}

		.sub-content {
			@extend %container;

			.sub-checkbox-wrap {
				display: flex;
				flex-direction: column;
			}

			input, textarea {
				font-size: $font-size-med;
				font-weight: $font-weight-regular;
			}

			.checkbox-container {
				align-items: center;
				cursor: pointer;
				display: inline-flex;
				font-size: $font-size-large;
				font-weight: $font-weight-light;
				margin-bottom: 30px;

				&:last-of-type {
					margin-bottom: 0;
				}

				.checkmark {
					background: $color-white;
					border: 1px solid $color-borders;
					display: inline-block;
					height: 25px;
					margin-right: 20px;
					margin-top: 3px;
					min-width: 25px;
					order: 0;
					position: relative;
					width: 25px;
				}

				.sub-label {
					order: 1;
				}

				input {
					height: 0;
					opacity: 0;
					width: 0;

					&:checked {
						~ .checkmark {
							background: $color-primary;
							border-color: $color-primary;

							&:after {
								content: '';
								border: solid black;
								border-width: 0 2px 2px 0;
								display: block;
								height: 12px;
								left: 8px;
								position: absolute;
								top: 2px;
								transform: rotate(45deg);
								width: 5px;
							}
						}
					}
				}
			}

			.sub-errors {
				margin-bottom: 35px;

				.sub-error {
					strong {
						color: $color-error;
						font-size: $font-size-med;
						font-weight: $font-weight-regular;
					}
				}

				span {
					color: $color-error;
					display: block;
				}
			}

			.sub-info {
				.btn {
					margin-top: 50px;
				}
			}
		}
	}

	.sub-wrap-btn {
		display: flex;
		margin-top: 35px;

		.btn {
			margin-top: 0;
		}

		#list-files {
			@extend %list-default;

			align-items: center;
			display: flex;
			margin-left: 20px;

			li {
				margin-right: 5px;

				&:after {
					content: ",";
				}

				&:last-child:after {
					content: none;
				}
			}
		}
	}

	header.sub-row {
		align-items: center;
		border-bottom: 1px solid $color-borders;
		display: flex;
		margin-bottom: 45px;
		padding-bottom: 45px;

		h3 {
			font-size: $font-size-h1;
			margin-bottom: 10px;
		}

		.sub-form-info {
			flex: 1;
		}

		.sub-control {
			.btn {
				margin: 0;
				min-width: 165px;

				@include mq(tablet-down) {
					margin-top: 30px;
				}
			}
		}
	}

	.sub-row {
		border-bottom: 1px solid $color-borders;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 65px;
		padding-bottom: 65px;

		@include mq(tablet-down) {
			flex-direction: column;
			margin-bottom: 30px;
			padding-bottom: 30px;
		}


		&.mod-no-border {
			border: none !important;
			margin-bottom: 0;
		}

		.checkbox-container {
			display: flex;

			.checkmark {
				order: 0;
			}

			.sub-title {
				order: 1;

				@include mq(tablet-down) {
					font-size: $font-size-med-large;
				}
			}
		}

		.row-title {
			display: flex;
			flex-direction: column;
			max-width: 160px;
			width: 100%;

			@include mq(tablet-down) {
				margin-bottom: 30px;
				max-width: none;
			}


			span {
				&:first-child {
					font-size: $font-size-larger;
				}

				&:nth-child(2) {
					font-size: $font-size-small-med;
				}
			}
		}

		.mod-margin-top {
			margin-top: 30px;
		}

		.form-input {
			flex: 1;

			label {
				font-size: $font-size-med;
			}

			.sub-selection {
				display: flex;
				flex-wrap: wrap;

				div {
					align-items: flex-start;
					margin-bottom: 70px;
					display: flex;
					flex: 0 0 50%;
					flex-direction: column;

					@include mq(tablet-down) {
						margin-bottom: 40px;
					}


					&:nth-last-child(2), &:last-child {
						margin-bottom: 0;

						@include mq(tablet-down) {
							margin-bottom: 40px;
						}
					}

					img {
						margin-bottom: 15px;

						@include mq(tablet-down) {
							margin-bottom: 30px;
						}
					}
				}
			}
		}

		.checkbox-container {
			display: flex;

			.checkmark {
				order: 0;
			}

			span.sub-title {
				order: 1;

				@include mq(tablet-down) {
					font-size: $font-size-med-large;
				}
			}
		}

		&.mod-multiselect {
			.form-input .sub-selection {
				.checkbox-container {
					flex: 0 0 33.333%;

					@include mq(tablet-down) {
						align-items: flex-start;
						flex: 0 0 50%;
					}
				}
			}

			.sub-input-field {
				align-items: center;
				display: flex;

				label {
					font-size: $font-size-med;
					margin-right: 30px;
				}

				.form-element {
					max-width: 400px;
					margin-bottom: 0;
				}
			}

			.sub-split {
				display: flex;
				flex-wrap: wrap;

				@include mq(tablet-down) {
					flex-direction: column;
				}


				.sub-input-field {
					flex: 0 0 50%;
					margin-bottom: 30px;

					&:nth-last-child(2), &:last-child {
						margin-bottom: 0;

						@include mq(tablet-down) {
							margin-bottom: 30px;
						}
					}

					label {
						font-size: $font-size-med;
						max-width: 120px;
						width: 100%;
					}
				}
			}
		}

		&.mod-split-inputs {
			.mod-full-width {
				margin-bottom: 30px;
				width: 100%;
			}

			.sub-selection {
				display: flex;
				flex-wrap: wrap;

				@include mq(tablet-down) {
					flex-direction: column;
				}


				.sub-input-field {
					align-items: center;
					display: flex;
					flex: 0 0 50%;
					margin-bottom: 30px;

					@include mq(tablet-down) {
						flex: 1;
					}


					&:last-child {
						margin-bottom: 0;
					}

					label {
						font-size: $font-size-med;
						max-width: 120px;
						width: 100%;
					}

					.form-element {
						margin-bottom: 0;
						max-width: 300px;
						width: 100%;
					}
				}
			}
		}

		&.mod-two-line-checkbox {
			.form-input {
				align-items: center;
				display: flex;
				flex: 0 0 50%;
				margin-bottom: 30px;

				label {
					font-size: $font-size-med;
					max-width: 150px;
					width: 100%;
				}

				&:nth-last-child(2), &:last-child {
					margin-bottom: 0;

					@include mq(tablet-down) {
						margin-bottom: 30px;
					}
				}

				.sub-selection {
					@include mq(tablet-down) {
						flex-wrap: nowrap;
					}


					.checkbox-container {
						margin-bottom: 0;
						margin-right: 20px;
					}
				}
			}
		}

		&.mod-column.mod-sized-labels {
			display: flex;
			flex-direction: column;

			.form-element {
				margin-bottom: 0;
				max-width: 650px;
				width: 100%;
			}

			.sub-input-field {
				align-items: flex-start;
				display: flex;
				margin-bottom: 30px;

				&:last-child {
					margin-bottom: 0;
				}

				label {
					font-size: $font-size-med;
					max-width: 150px;
					width: 100%;
				}
			}

			.sub-wrap {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				width: 100%;
			}

			.sub-upload {
				.btn {
					margin-left: 0;
				}
			}
		}

		&.mod-last-row {
			display: flex;
			flex-direction: column;

			.row-title {
				width: 100%;
				margin-bottom: 35px;
			}

			.sub-wrap {
				display: flex;
				flex-wrap: wrap;

				@include mq(tablet-down) {
					flex-direction: column;
				}


				.sub-input-field {
					align-items: center;
					display: flex;
					flex: 0 0 50%;
					margin-bottom: 30px;

					@include mq(tablet-down) {
						flex: 1;
					}


					label {
						font-size: $font-size-med;
						display: inline-block;
						max-width: 150px;
						width: 100%;
					}

					.form-element {
						margin-bottom: 0;

						input {
							max-width: 400px;
							width: 100%;
						}
					}
				}
			}

			.sub-disclaimer {
				display: inline-block;
				margin: 30px 0;
				font-size: $font-size-small-med;
			}

			.sub-control {
				margin-top: 35px;

				.checkbox-container {
					display: flex;

					.checkmark {
						order: 0;
					}

					.sub-title {
						order: 1;

						@include mq(tablet-down) {
							font-size: $font-size-med-large;
						}
					}
				}

				.btn {
					margin-left: 0;
					margin-top: 60px;
				}
			}
		}

		&.mod-coil-type {
			.sub-selection {
				.checkbox-container {
					display: flex;
					flex: 0 0 33.333%;

					@include mq(tablet-down) {
						flex: 0 0 50%;
					}


					.sub-title {
						order: 1;

						@include mq(tablet-down) {
							font-size: $font-size-med-large;
						}
					}

					.checkmark {
						order: 0;
					}
				}
			}

			.sub-input-field {
				align-items: center;
				display: flex;

				label {
					font-size: $font-size-med;
					max-width: 250px;
					width: 100%;
				}

				.form-element {
					margin-bottom: 0;
					max-width: 400px;
					width: 100%;
				}
			}
		}

		&.mod-split-inputs.mod-new {
			.sub-wrap {
				display: flex;
				flex-wrap: wrap;

				@include mq(tablet-down) {
					flex-direction: column;
				}


				.sub-input-field {
					align-items: center;
					display: flex;
					flex: 0 0 50%;
					margin-bottom: 30px;

					@include mq(tablet-down) {
						flex: 1;
					}


					label {
						font-size: $font-size-med;
						max-width: 150px;
						width: 100%;
					}

					.form-element {
						margin-bottom: 0;
						max-width: 400px;
						width: 100%;
					}
				}
			}

			.mod-full-length {
				display: flex;
				margin-top: 30px;
				width: 100%;

				label {
					display: inline-block;
					font-size: $font-size-med;
					max-width: 150px;
					width: 100%;
				}

				textarea {
					height: 175px;
					max-width: 650px;
					width: 100%;
				}
			}
		}

		&.mod-normal-labels.mod-new {
			.row-title {
				margin-bottom: 30px;
				width: 100%;
			}

			.sub-wrap {
				display: flex;
				width: 100%;

				.sub-input-field {
					align-items: center;
					display: flex;
					flex: 0 0 50%;
					margin-bottom: 30px;
				}

				label {
					display: inline-block;
					font-size: $font-size-med;
					max-width: 150px;
					width: 100%;
				}

				.form-element {
					margin-bottom: 0;
					max-width: 400px;
					width: 100%;
				}
			}
		}

		&.mod-unit-new {
			.row-title {
				max-width: 150px;
			}

			label {
				display: inline-block;
				font-size: $font-size-larger;
				max-width: 150px;
				width: 100%;
			}

			.sub-input-field {
				align-items: center;
				display: flex;
				max-width: 400px;
				width: 100%;

				.form-element {
					margin-bottom: 0;
					margin-right: 20px;
				}

				span {
					display: block;
					max-width: 150px;
					width: 100%;
				}
			}
		}

		&.mod-new-quant {
			flex-direction: column;
			display: flex;

			label {
				font-size: $font-size-med;
				max-width: 150px;
				width: 100%;
			}

			.sub-input-field {
				align-items: center;
				display: flex;
				margin-bottom: 30px;
				width: 100%;

				.form-element {
					margin-bottom: 0;
					margin-right: 20px;
					max-width: 650px;
					width: 100%;
				}

				span {
					display: block;
					max-width: 150px;
					width: 100%;
				}
			}
		}
	}
}

#quoteMain {
	.section-quote {
		padding-bottom: 250px;
	}
}

.section-thanks {
	display: none;
	padding: 200px 0;
	text-align: center;

	.sub-content {
		@extend %container;

		p {
			font-size: 30px;
		}
	}
}
