h1,
h2,
h3,
h4,
h5 {
	color: $headings-color;
	font-weight: $headings-font-weight;
	line-height: $headings-line-height;
	margin-bottom: 40px;
	padding: 0;
	position: relative;
	margin-top: 0;

	&.mod-line {
		align-items: center;
		display: inline-flex;

		@include mq(tablet-down) {
			align-items: flex-start;
		}


		&:after {
			background-color: $color-primary;
			border-radius: 1.5px;
			content: '';
			height: 3px;
			margin-left: 20px;
			width: 70px;

			@include mq(tablet-down) {
				margin-top: 14px;
			}
		}

		@include mq(tablet-down) {
			&:after {
				width: 35px;
			}
		}
	}
}

h1 {
	font-size: $font-size-h1;
}

h2 {
	font-size: $font-size-h2;
}

h3 {
	font-size: $font-size-h3;
}

h4 {
	font-size: $font-size-h4;
}

h5 {
	font-size: $font-size-h5;
}

h6 {
	font-size: $font-size-h6;
}

p {
	margin: 0 0 35px;
}

strong {
	font-weight: $font-weight-semibold;
}

::selection {
	background: rgba($color-primary, 0.25);
}

::-moz-selection {
	background: rgba($color-primary, 0.25);
}
