#home {
	.section-hero {
		@extend %background-default;

		background-attachment: fixed;
		background-position: bottom -50px center;
		background-size: 100% auto;
		height: calc(100vh - 93px);
		position: relative;

		@include mq(tablet-down) {
			align-items: center;
			background-attachment: unset;
			background-size: cover;
			display: flex;
			height: calc(100vh - 65px);
		}


		&:before {
			background: linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.01) 0.14%, #FFFFFF 100%);
			content: '';
			height: 544px;
			left: 0;
			position: absolute;
			top: 70px;
			width: 100%;
		}

		.sub-content {
			@extend %container-small;

			align-items: flex-start;
			display: flex;
			height: 100%;
			justify-content: center;
			left: 50%;
			position: absolute;
			top: 19%;
			transform: translateX(-50%);

			@include mq(tablet-down) {
				align-items: flex-start;
				height: auto;
				left: auto;
				position: relative;
				top: auto;
				transform: none;
			}


			.sub-copy {
				text-align: center;

				@include mq(tablet-down) {
					padding: 0 10px;
				}


				h1 {
					font-size: $font-size-hero-med;
					font-weight: $font-weight-medium;
					line-height: 64px;
					margin-bottom: 25px;

					@include mq(tablet-down) {
						font-size: $font-size-h1;
						font-weight: $font-weight-regular;
						line-height: 39px;
					}
				}

				h2 {
					font-weight: $font-weight-light;
					font-size: $font-size-largest;
					line-height: 42px;

					@include mq(tablet-down) {
						font-size: $font-size-larger;
						line-height: 34px;
					}
				}
			}
		}
	}

	.section-what-we-do {
		padding: 115px 0 145px;

		@include mq(tablet-down) {
			padding: 35px 0;
		}


		.sub-content {
			@extend %container;

			h2 {
				font-size: $font-size-h1;
				font-weight: $font-weight-medium;

				@include mq(tablet-down) {
					font-size: 24px;
					margin-bottom: 20px;

					&:after {
						content: none;
					}
				}
			}

			.sub-copy {
				display: flex;

				@include mq(tablet-down) {
					flex-direction: column;
				}


				.sub-heading {
					flex: 1;
					max-width: 515px;
					margin-right: 93px;
					padding-right: 80px;
					width: 100%;

					p {
						font-size: $font-size-h1;
						font-weight: $font-weight-light;
						line-height: 53px;

						@include mq(tablet-down) {
							font-size: $font-size-large;
							line-height: 33px;
						}
					}
				}

				.sub-main-copy {
					flex: 1;

					p {
						font-size: $font-size-med-large;
						font-weight: $font-weight-light;
						line-height: 32px;

						@include mq(tablet-down) {
							font-size: $font-size-med;
							line-height: 25px;
						}
					}
				}
			}

			.list-what-we-do {
				@extend %list-default;

				display: flex;
				margin-top: 85px;

				@include mq(tablet-down) {
					flex-direction: column;
					margin-top: 40px;
				}


				& > li {
					flex: 1;
					position: relative;
					text-align: center;

					@include mq(tablet-down) {
						display: flex;
						justify-content: flex-start;
						margin-bottom: 40px;
						position: relative;
						text-align: left;
					}


					svg {
						display: none;

						@include mq(tablet-down) {
							color: $color-primary;
							display: block;
							height: 12px;
							position: absolute;
							right: 0;
							top: 50%;
							transform: translateY(-50%);
							width: 8px;
						}
					}

					.sub-link {
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;
						z-index: 9;

						&:hover {
							& ~ h4 {
								color: $color-text-light;
							}

							& ~ img {
								transform: translateY(-5px);
							}
						}
					}

					img {
						margin-bottom: 30px;
						transition: all .3s ease;

						@include mq(tablet-down) {
							align-self: center;
							margin-bottom: 0;
							margin-right: 45px;
							max-width: 45px;
						}
					}

					h4 {
						padding: 0 20px;
						transition: color .3s ease;

						@include mq(tablet-down) {
							font-size: $font-size-med-large;
							margin: 0;
							padding: 0 115px 0 0;
						}
					}
				}
			}
		}
	}

	.section-information {
		@extend %background-default;

		align-items: center;
		display: flex;
		height: 55vw;
		max-height: 692px;
		min-height: 570px;
		position: relative;

		@include mq(tablet-down) {
			height: auto;
			max-height: auto;
			min-height: auto;
			padding: 60px 0 75px;
		}


		.sub-content {
			@extend %container;

			.sub-copy {
				max-width: 520px;
				width: 100%;

				@include mq(tablet-down) {
					padding-right: 100px;
				}


				h3 {
					font-size: $font-size-h1;
					margin-bottom: 25px;

					@include mq(tablet-down) {
						font-size: $font-size-large;
					}
				}

				h4 {
					font-size: 30px;
					font-weight: $font-weight-light;
				}

				p {
					font-size: $font-size-med;
					line-height: 30px;

					@include mq(tablet-down) {
						font-size: $font-size-med;
						font-weight: $font-weight-medium;
						line-height: 27px;
					}


					&:only-child, &:last-child {
						margin-bottom: 0;
					}
				}

				.btn {
					color: $color-black;
					height: 45px;
					font-size: 17px;
					font-weight: $font-weight-regular;
					margin-top: 40px;
					max-width: 184px;
					width: 100%;

					@include mq(tablet-down) {
						align-items: center;
						font-size: $font-size-standard;
						display: flex;
						height: 37px;
						max-width: 173px;
						margin-top: 40px;
						min-height: auto;
						padding: 0;
					}
				}
			}
		}
	}

	.section-recent-updates {
		background-color: $color-neutral-light;
		padding: 115px 0 85px;

		@include mq(tablet-down) {
			padding: 53px 0;
		}


		.sub-content {
			@extend %container;

			h2 {
				font-size: $font-size-h1;
				font-weight: $font-weight-medium;
				margin-bottom: 25px;

				@include mq(tablet-down) {
					font-size: 24px;
					margin-bottom: 40px;
				}
			}

			& > a {
				color: $color-black;
				font-size: $font-size-larger;
				font-weight: $font-weight-light;

				svg {
					color: $color-primary;
					height: 21px;
					margin-left: 15px;
					width: 15px;
				}
			}

			.sub-posts {
				display: flex;
				margin-top: 58px;

				@include mq(tablet-down) {
					flex-direction: column;
				}


				.sub-post {
					background-color: $color-white;
					flex: 1;
					margin-right: 45px;
					padding: 35px;
					position: relative;

					@include mq(tablet-down) {
						margin-bottom: 10px;
						margin-right: 0;
						padding: 20px 15px;
					}


					& > a {
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;
						z-index: 999;

						&:hover ~ h3 {
							color: $color-primary;
						}

						&:hover ~ .sub-image-wrap .sub-image {
							transform: scale(1.03);
						}
					}

					&:last-of-type {
						margin-right: 0;
					}

					h3 {
						font-weight: $font-weight-regular;
						font-size: $font-size-h2;
						padding-right: 75px;
						margin-bottom: 30px;
						min-height: 80px;
						transition: color .3s ease;

						@include mq(tablet-down) {
							line-height: 26px;
							margin-bottom: 15px;
							padding-right: 0;
						}
					}

					h5 {
						color: $color-text-light;
						font-size: $font-size-small;
						font-weight: $font-weight-light;
						margin-bottom: 20px;
					}

					.sub-copy {
						padding-right: 40px;

						p {
							font-size: $font-size-small-med;
							font-weight: $font-weight-light;

							@include mq(tablet-down) {
								font-size: $font-size-standard;
							}
						}
					}

					.sub-image-wrap {
						background-color: #f7f7f7;
						height: 205px;
						overflow: hidden;

						@include mq(mobile) {
							height: 190px;
						}


						.sub-image {
							@extend %background-default;

							background-position: top left;
							height: 100%;
							transition: all .3s ease;
							width: 100%;
						}
					}
				}
			}
		}
	}
}
