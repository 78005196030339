// Define placeholders within here to be used within further sass.
%container {
	width: $container-width;
	max-width: $container-max-width;
	margin: 0 auto;

	// Example of how to extend container. Used via `@extend %container-large`
	&-large {
		@extend %container;

		max-width: $container-max-width-large;
	}

	&-small {
		@extend %container;

		max-width: $container-max-width-small;
	}

	@include mq(mobile) {
		width: $container-width-mobile;
		max-width: $container-max-width-mobile;
		margin: 0 auto;
	}
}

%clearfix {
	zoom: 1;

	&:after {
		clear: both;
	}

	&:before,
	&:after {
		content: "";
		display: table;
	}
}

%noselect {
	-webkit-touch-callout: none;
	user-select: none;
}

%nofocus {
	&:focus {
		outline-style: none;
		box-shadow: none;
	}
}

%list-default {
	margin: 0;
	padding: 0;
	list-style: none;

	& > li {
		margin: 0;
		padding: 0;
	}
}

%background-default {
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;

	&-contain {
		@extend %background-default;

		background-size: contain;
	}

	&-mod-fade {
		@extend %background-default;

		&:before {
			content: "";
			background: linear-gradient(90deg, #ffffff 25vw, transparent);
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}
}

%hero-default {
	@extend %background-default;

	background-position: right center;
	align-items: center;
	display: flex;
	height: 457px;
	position: relative;

	@include mq(tablet-down) {
		align-items: flex-start;
		height: auto;
		min-height: 315px;
		padding-top: 40px;
	}

	&:before {
		background: linear-gradient(
			270deg,
			hsla(0, 0%, 100%, 0),
			hsl(0, 0%, 100%) 50%,
			hsl(0, 0%, 100%)
		);
		content: "";
		display: block;
		height: 100%;
		left: 0;
		max-width: 1000px;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 9;

		@include mq(tablet-down) {
			background: linear-gradient(
				270deg,
				rgba(255, 255, 255, 0) 0%,
				#ffffff 100%
			);
			content: "";
			left: 0;
			opacity: 0.88;
			top: 0;
		}
	}

	.sub-content {
		@extend %container;

		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 99;

		@include mq(tablet-down) {
			left: auto !important;
			opacity: 1 !important;
			position: relative;
			top: auto !important;
			transform: none !important;
		}

		.sub-copy {
			max-width: 545px;
			width: 100%;

			@include mq(tablet-down) {
				padding-right: 50px;
			}

			h1 {
				font-size: $font-size-hero-small;
				font-weight: $font-weight-medium;
				margin-bottom: 40px;

				@include mq(tablet-down) {
					font-size: $font-size-largest;
					line-height: 36px;
					margin-bottom: 20px;
				}
			}

			p {
				color: $color-headings;
				font-size: $font-size-h2;
				font-weight: $font-weight-light;
				line-height: 47px;

				@include mq(tablet-down) {
					font-size: $font-size-med-large;
					line-height: 30px;
				}
			}
		}
	}

	&-carousel {
		@extend %hero-default;

		.sub-image {
			@extend %background-default;

			background-position: right center;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		.list-carousel {
			@extend %list-default;

			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;

			.slick-list,
			.slick-track,
			.slick-slide {
				height: 100%;
			}

			.slick-slide > div {
				height: 100%;

				li {
					height: 100%;
				}
			}

			li {
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				height: 100%;
			}
		}
	}
}

%image {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;

	&-cover {
		@extend %image;

		object-fit: cover;

		@include mq(tablet-down) {
			object-position: top center;
		}
	}

	&-contain {
		@extend %image;

		object-fit: contain;
	}
}

%copy-with-testimonials {
	padding: 100px 0;

	@include mq(tablet-down) {
		padding: 38px 0 56px;
	}

	.sub-content {
		@extend %container;

		display: flex;

		@include mq(tablet-down) {
			flex-direction: column;
		}

		.sub-copy {
			flex: 1;
			max-width: 732px;
			margin-right: 110px;

			@media only screen and (max-width: 1135px) {
				max-width: 850px;
				margin-right: 70px;
			}

			@include mq(tablet-down) {
				margin-right: 0;
				max-width: 100%;
			}

			p,
			ul li {
				font-size: $font-size-med-large;
				font-weight: $font-weight-light;
				line-height: 32px;

				@include mq(tablet-down) {
					font-size: $font-size-med;
				}
			}
		}

		.sub-testimonials {
			flex: 1;
			margin-left: auto;
			max-width: 468px;
			position: relative;
			width: 100%;

			@media only screen and (max-width: 1135px) {
				max-width: 400px;
			}

			@include mq(tablet-down) {
				max-width: none;
				margin-top: 50px;
			}

			.list-testimonials,
			.list-testimonials-tech {
				@extend %list-default;

				.slick-slide {
					li {
						margin: 0;
						padding: 0;
					}
				}

				blockquote {
					margin: 0;
					padding: 0;

					p {
						color: $color-black;
						font-size: 30px;
						font-weight: $font-weight-regular;
						line-height: 39px;

						@include mq(tablet-down) {
							font-size: $font-size-med-large;
							line-height: 31px;
						}
					}
				}

				.sub-testimonial-name {
					color: $color-text-alt;
					font-size: $font-size-med;
					font-weight: $font-weight-medium;

					@include mq(tablet-down) {
						font-size: $font-size-standard;
					}
				}
			}

			.sub-testimonials-nav {
				bottom: 28px;
				position: absolute;
				right: 0;

				@include mq(tablet-down) {
					bottom: 2px;
				}

				ul {
					@extend %list-default;

					display: flex;

					li {
						cursor: pointer;

						svg {
							color: $color-secondary;
							height: 13px;
							width: 18px;
						}

						&.slick-disabled {
							cursor: default;

							svg {
								color: $color-text;
							}
						}

						&.sub-prev {
							margin-right: 10px;

							svg {
								transform: rotate(-180deg);
							}
						}
					}
				}
			}
		}
	}
}

%btn-mobile {
	@include mq(tablet-down) {
		align-items: center;
		font-size: $font-size-standard;
		display: flex;
		height: 37px;
		margin-left: 0;
		min-height: auto;
		margin-top: 20px;
		padding: 0;
		width: 100%;
	}
}

.modal {
	background: rgba($color-black, 0.85);
	height: 100%;
	left: 0;
	opacity: 0;
	position: fixed;
	transition: opacity 0.2s ease, visibility 0.2s ease;
	top: 0;
	visibility: hidden;
	width: 100%;
	z-index: -1;

	.modal-close {
		cursor: pointer;
		height: 45px;
		position: absolute;
		right: -40px;
		top: -50px;
		transition: transform 0.2s ease;
		width: 45px;

		@include mq(tablet-down) {
			right: 10px;
			top: -30px;
		}

		.close-bar-1,
		.close-bar-2 {
			background-color: $color-primary;
			height: 2px;
			position: absolute;
			width: 90%;
			transform: translateY(11px) rotate(45deg);

			&.close-bar-2 {
				transform: translateY(11px) rotate(-45deg);
			}
		}
	}

	&.modal-open,
	&.modal-open .modal-content {
		opacity: 1;
		visibility: visible;
		z-index: 999;
	}

	.modal-content {
		height: auto;
		left: 50%;
		max-width: 860px;
		opacity: 0;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		transition: opacity 0.7s ease, visibility 0.7s ease;
		visibility: hidden;
		width: 95%;

		@include mq(tablet-down) {
			padding: 30px 20px;
		}

		iframe {
			@include mq(mobile) {
				height: 50vw !important;
			}
		}
	}
}

.modal-gallery {
	.sub-gallery {
		position: relative;

		ul.list-gallery {
			@extend %list-default;

			li {
				margin: 0;
				padding: 0;

				img {
					height: auto !important;
					max-width: 100%;
				}
			}
		}

		.sub-gallery-nav {
			left: 0;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;

			ul {
				@extend %list-default;

				align-items: center;
				display: flex;

				& > li {
					svg {
						color: #EFC01D;
						cursor: pointer;
						height: 12px;
						transform: scale(2);
						width: 10px;
					}

					&.sub-next {
						margin-left: auto;
						margin-right: 15px;
					}

					&.sub-prev {
						margin-left: 15px;

						svg {
							transform: rotate(180deg) scale(2);
						}
					}
				}
			}
		}
	}
}