#solution {
	.section-hero {
		@extend %hero-default;

		.sub-content .sub-copy {
			max-width: 655px;
		}
	}

	.section-main-copy {
		@extend %copy-with-testimonials;
	}

	.section-sectors {
		background-color: #f7f7f7;
		padding: 82px 0;

		@include mq(tablet-down) {
			padding: 37px 0 70px;
		}


		.sub-content {
			@extend %container;

			h2 {
				font-size: $font-size-h1;

				@include mq(tablet-down) {
					font-size: $font-size-larger;
				}
			}

			.list-sectors {
				@extend %list-default;

				flex-wrap: wrap;
				display: flex;
				margin-top: 25px;

				@include mq(tablet-down) {
					flex-direction: column;
					margin-top: 0;
				}


				& > li {
					background-color: $color-white;
					display: flex;
					flex-direction: column;
					margin-bottom: 38px;
					margin-right: 26px;
					position: relative;
					width: calc(25% - 20px);

					@include mq(tablet-down) {
						margin-bottom: 18px;
						padding: 20px;
						width: 100%;
					}


					&:nth-child(4n), &:last-child {
						margin-right: 0;
					}

					.sub-details {
						display: flex;
						flex-direction: column;
						flex-grow: 1;
						padding: 25px 17px;

						h3 {
							font-size: $font-size-med-large;
							font-weight: $font-weight-medium;
							margin-bottom: 25px;
							min-height: 25px;

							@include mq(tablet-down) {
								min-height: auto;
							}
						}

						.sub-copy {
							margin-bottom: 30px;

							p {
								color: $color-text;
								font-size: $font-size-small-med;
								font-weight: $font-weight-light;
								line-height: 24px;

								@include mq(tablet-down) {
									font-size: $font-size-med;
									line-height: 30px;
								}


								&:only-child, &:last-child {
									margin: 0;
								}
							}
						}

						a {
							color: $color-black;
							font-size: $font-size-small;
							font-weight: $font-weight-medium;
							margin-top: auto;

							svg {
								height: 10px;
								margin-left: 5px;
								width: 13px;
							}
						}
					}

					.sub-image {
						@extend %background-default;

						background-color: $color-black;
						filter: grayscale(1);
						height: 175px;
						transition: all .3s ease;
					}

					.sub-link-wrap {
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;
						z-index: 9;

						&:hover {
							& + {
								.sub-image {
									filter: grayscale(0);
								}
							}
						}
					}
				}
			}
		}
	}

	.section-logos {
		@extend %background-default;

		padding: 90px 0 115px;

		@include mq(tablet-down) {
			padding: 46px 0 20px;
		}


		.sub-content {
			@extend %container;

			h3 {
				color: $color-white;
				font-size: $font-size-h1;
				margin-bottom: 60px;

				@include mq(tablet-down) {
					font-size: $font-size-larger;
					margin-bottom: 25px;
				}
			}

			.list-logos {
				@extend %list-default;

				display: flex;
				flex-wrap: wrap;

				li {
					margin-bottom: 70px;
					width: calc(100% / 6);

					@include mq(tablet-down) {
						margin-bottom: 25px;
						margin-right: 34px;
						width: calc((100% / 3) - 23px);

						&:nth-child(3n) {
							margin-right: 0;
						}

						img {
							width: 100%;
						}
					}


					&:nth-of-type(6n+1):nth-last-of-type(-n+6), &:nth-of-type(6n+1):nth-last-of-type(-n+6) ~ li {
						margin-bottom: 0;

						@include mq(tablet-down) {
							margin-bottom: 25px;
						}
					}
				}
			}
		}
	}

	.section-what-we-do {
		padding: 115px 0 145px;

		@include mq(tablet-down) {
			padding: 35px 0;
		}


		.sub-content {
			@extend %container;

			h2 {
				font-size: $font-size-h1;

				@include mq(tablet-down) {
					font-size: 24px;
					margin-bottom: 20px;

					&:after {
						content: none;
					}
				}
			}

			.list-what-we-do {
				@extend %list-default;

				display: flex;
				margin-top: 85px;

				@include mq(tablet-down) {
					flex-direction: column;
					margin-top: 40px;
				}


				& > li {
					flex: 1;
					position: relative;
					text-align: center;

					@include mq(tablet-down) {
						display: flex;
						justify-content: flex-start;
						margin-bottom: 40px;
						position: relative;
						text-align: left;
					}


					svg {
						display: none;

						@include mq(tablet-down) {
							color: $color-primary;
							display: block;
							height: 12px;
							position: absolute;
							right: 0;
							top: 50%;
							transform: translateY(-50%);
							width: 8px;
						}
					}

					.sub-link {
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;
						z-index: 9;

						&:hover {
							& ~ h4 {
								color: $color-primary;
							}

							& ~ img {
								transform: translateY(-5px);
							}
						}
					}

					img {
						margin-bottom: 30px;
						transition: all .3s ease;

						@include mq(tablet-down) {
							align-self: center;
							margin-bottom: 0;
							margin-right: 45px;
							max-width: 45px;
						}
					}

					h4 {
						padding: 0 20px;
						transition: color .3s ease;

						@include mq(tablet-down) {
							font-size: $font-size-med-large;
							margin: 0;
							padding: 0 115px 0 0;
						}
					}
				}
			}
		}
	}

	.section-information {
		@extend %background-default;

		align-items: center;
		display: flex;
		height: 55vw;
		max-height: 692px;
		min-height: 570px;
		position: relative;

		@include mq(tablet-down) {
			height: auto;
			max-height: auto;
			min-height: auto;
			padding: 60px 0 75px;
		}


		.sub-content {
			@extend %container;

			.sub-copy {
				max-width: 520px;
				width: 100%;

				@include mq(tablet-down) {
					padding-right: 100px;
				}


				h3 {
					font-size: $font-size-h1;
					margin-bottom: 25px;

					@include mq(tablet-down) {
						font-size: $font-size-large;
					}
				}

				h4 {
					font-size: 30px;
					font-weight: $font-weight-light;
				}

				p {
					font-size: $font-size-med;
					line-height: 30px;

					@include mq(tablet-down) {
						font-size: $font-size-med;
						font-weight: $font-weight-medium;
						line-height: 27px;
					}


					&:only-child, &:last-child {
						margin-bottom: 0;
					}
				}

				.btn {
					color: $color-black;
					height: 45px;
					font-size: 17px;
					font-weight: $font-weight-regular;
					margin-top: 40px;
					max-width: 184px;
					width: 100%;

					@include mq(tablet-down) {
						align-items: center;
						font-size: $font-size-standard;
						display: flex;
						height: 37px;
						max-width: 173px;
						margin-top: 40px;
						min-height: auto;
						padding: 0;
					}
				}
			}
		}
	}
}
