.header-main {
	align-items: center;
	background: $color-white;
	display: flex;
	height: 97px;

	@include mq(tablet-down) {
		height: 65px;
		position: relative;
	}


	.sub-container {
		display: flex;
		height: 100%;
		padding-left: 60px;
		width: 100%;

		@include mq(tablet-down) {
			padding: 0 22px;
		}


		.sub-logo {
			align-items: center;
			display: flex;

			img {
				height: auto;

				@media only screen and (max-width: 1135px) {
					width: 160px;
				}

				@include mq(tablet-down) {
					width: 140px;
				}
			}
		}

		#nav {
			align-items: center;
			display: flex;
			height: 100%;
			margin-left: auto;
			padding: 0 67px;

			@media only screen and (max-width: 1330px) {
				padding: 0 40px;
			}

			@include mq(tablet-down) {
				align-items: flex-start;
				background: $color-primary;
				border-radius: 0;
				box-shadow: none;
				display: flex;
				height: 101vh;
				opacity: 0;
				overflow: scroll;
				padding: 87px 20px 20px;
				position: absolute;
				left: 0;
				top: 0;
				transition: opacity .3s ease, visibility .3s ease;
				visibility: none;
				width: 100%;
				z-index: -1;

				&.mod-active {
					opacity: 1;
					z-index: 999;
				}
			}


			ul.list-nav {
				@extend %list-default;

				height: 100%;

				@include mq(tablet-down) {
					display: flex;
					flex-direction: column;
					height: auto;
					width: 100%;
				}


				li {
					display: inline-block;
					height: 100%;
					margin-right: 42px;
					position: relative;

					@media only screen and (max-width: 1330px) {
						margin-right: 40px;
					}

					@include mq(tablet-down) {
						height: auto;
						margin-bottom: 23px;
						margin-right: 0;
					}


					&:nth-last-child(2) {
						margin-right: 0;
					}

					&.mod-quote {
						margin-left: 72px;
						margin-right: 0;

						@media only screen and (max-width: 1330px) {
							margin-left: 50px;
						}

						@include mq(tablet-down) {
							margin: 0;
						}


						a {
							background-color: $color-primary;
							border-radius: 31px;
							height: auto;
							padding: 5px 20px;

							@include mq(tablet-down) {
								display: block;
								max-width: none;
								padding: 0;
								text-align: left;
								width: 100%;

								&:before {
									content: '';
									display: none;
								}
							}
						}
					}

					& > a {
						align-items: center;
						color: $color-black;
						display: flex;
						font-size: 17px;
						font-weight: $font-weight-medium;
						height: 100%;

						@include mq(tablet-down) {
							font-size: 24px;
							height: auto;
							letter-spacing: 0.6px;
						}


						&.mod-dropdown {
							cursor: default;

							@include mq(tablet-down) {
								cursor: pointer;
							}


							&.mod-active svg {
								@include mq(tablet-down) {
									transform: rotate(90deg);
								}
							}

							svg {
								display: none;

								@include mq(tablet-down) {
									color: $color-black;
									display: block;
									height: 12px;
									margin-left: 35px;
									margin-top: 5px;
									transition: all .3s ease;
									width: 8px;
								}
							}
						}

						&:hover + .sub-dropdown {
							opacity: 1;
							z-index: 999;
						}
					}

					.sub-dropdown {
						background: $color-white;
						border-radius: 0px 12px 12px 12px;
						box-shadow: 23px 23px 50px -12px rgba(0, 0, 0, 0.48);
						left: 0;
						margin: 0;
						opacity: 0;
						padding: 7px 15px;
						position: absolute;
						top: 70px;
						transition: opacity .3s ease;
						width: 282px;
						z-index: -1;

						@include mq(tablet-down) {
							background: none;
							border-radius: 0;
							box-shadow: none;
							max-height: 0;
							opacity: 1;
							overflow: hidden;
							padding: 0;
							position: static;
							top: auto;
							transition: all .3s ease;
							width: 100%;

							&.mod-active {
								max-height: 500px;
								padding-top: 25px;
							}
						}


						&:hover {
							opacity: 1;
							z-index: 999;
						}

						& > li {
							margin: 0;
							width: 100%;

							@include mq(tablet-down) {
								margin-bottom: 23px;
							}


							a {
								color: $color-headings;
								font-size: $font-size-small-med;
								font-weight: $font-weight-regular;
								display: block;
								padding: 15px 0;
								transition: color .3s ease;
								width: 100%;

								@include mq(tablet-down) {
									color: $color-black;
									font-size: $font-size-med-large;
									letter-spacing: normal;
									padding: 0;
								}


								&:hover {
									color: $color-primary;

									@include mq(tablet-down) {
										color: $color-white;
									}
								}
							}

							&:not(:last-child) {
								border-bottom: 1px solid #D8D8D8;

								@include mq(tablet-down) {
									border-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
	}

	.mob-hamburger {
		@include mq(tablet-down) {
			cursor: pointer;
			display: flex;
			flex-direction: column;
			height: 20px;
			justify-content: center;
			overflow: hidden;
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			transition: width .3s ease;
			width: 23px;
			z-index: 9999;

			.mob-bar {
				background: $color-primary;
				border-radius: 20px;
				display: block;
				height: 2px;
				left: 0;
				margin-bottom: 8px;
				opacity: 1;
				position: absolute;
				transform: rotate(0deg);
				transition: .4s ease-in-out;
				width: 100%;
				z-index: 9999;
			}

			.mob-bar-1 {
				top: 0;
			}

			.mob-bar-2 {
				top: 8px;
			}

			.mob-bar-3 {
				margin-bottom: 0;
				top: 16px;
				width: 14px;
			}

			&.mod-active {
				width: 28px;

				.mob-bar {
					background: $color-black;
				}

				.mob-bar-1 {
					top: 9px;
					transform: rotate(135deg);
				}

				.mob-bar-2 {
					left: -60px;
					opacity: 0;
				}

				.mob-bar-3 {
					transform: rotate(-135deg);
					top: 9px;
					width: 100%;
				}
			}
		}
	}
}

.section-cookies {
	@extend %container-large;

	align-items: center;
	background: $color-black;
	border-radius: 6px;
	bottom: 19px;
	display: flex;
	height: 63px;
	left: 50%;
	padding: 0 21px;
	position: fixed;
	transform: translateX(-50%);
	width: 100%;
	z-index: 999;

	@include mq(tablet-down) {
		height: auto;
		padding: 20px;
	}


	&.mod-hide {
		display: none;
	}

	.sub-content {
		align-items: center;
		display: flex;
		width: 100%;

		@include mq(tablet-down) {
			flex-direction: column;
		}


		.sub-text {
			flex: 1;

			& > p {
				color: $color-white;
				font-size: $font-size-standard;
				font-weight: $font-weight-regular;
				margin-bottom: 0;

				a {
					font-weight: $font-weight-semibold;
					color: $color-white;
				}
			}
		}

		.sub-btns {
			align-items: center;
			display: flex;
			margin-left: 70px;

			@include mq(tablet-down) {
				margin-left: 0;
				margin-top: 20px;
			}


			.btn {
				background: $color-white;
				color: $color-black;
				display: inline-block;
				font-size: $font-size-standard;
				font-weight: $font-weight-regular;
				height: 36px;
				transition: background .3s ease;
				width: 111px;

				@include mq(tablet-down) {
					font-size: $font-size-small;
					padding: 4px 0;
					width: 115px;
				}


				&:first-of-type {
					margin-right: 10px;
				}

				&:hover {
					background: rgba($color-white, 0.25);
				}
			}
		}
	}
}
