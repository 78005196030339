#about, #history {
	.section-hero {
		@extend %hero-default;
	}

	.section-main-copy {
		@extend %copy-with-testimonials;
	}

	.section-different {
		background-color: #f7f7f7;
		padding: 82px 0;

		@include mq(tablet-down) {
			padding: 40px 0;
		}


		.sub-content {
			@extend %container;

			& > h2 {
				font-size: $font-size-h1;
				font-weight: $font-weight-medium;
				margin-bottom: 65px;

				@include mq(tablet-down) {
					font-size: $font-size-larger;
				}
			}

			.list-sectors {
				@extend %list-default;

				flex-wrap: wrap;
				display: flex;
				margin-top: 25px;

				@include mq(tablet-down) {
					flex-direction: column;
					margin-top: 0;
				}


				& > li {
					background-color: $color-white;
					margin-bottom: 38px;
					margin-right: 30px;
					position: relative;
					width: calc(50% - 15px);

					@include mq(tablet-down) {
						width: 100%;
					}


					&:nth-child(2n), &:last-child {
						margin-right: 0;
					}

					.sub-link {
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;
						z-index: 999;
					}

					.sub-image {
						@extend %background-default;

						height: 220px;

						@include mq(tablet-down) {
							height: 153px;
						}
					}

					.sub-details {
						padding: 40px 25px 65px;

						@include mq(tablet-down) {
							padding: 30px 15px 50px;
						}


						h3 {
							font-size: $font-size-larger;
							font-weight: $font-weight-regular;
							margin-bottom: 20px;

							@include mq(tablet-down) {
								font-size: $font-size-med-large;
								margin-bottom: 10px;
							}
						}

						.sub-copy {
							p {
								color: $color-text;
								font-size: $font-size-small-med;
								font-weight: $font-weight-light;
								line-height: 24px;

								&:last-child, &:only-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
	}

	.section-gallery {
		align-items: center;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		height: 690px;
		position: relative;

		@include mq(tablet-down) {
			height: 420px;
		}


		.sub-content {
			@extend %container;

			.sub-copy {
				max-width: 630px;
				width: 100%;

				h3 {
					color: $color-white;
					font-size: $font-size-h1;
					font-weight: $font-weight-medium;
					margin-bottom: 45px;

					@include mq(tablet-down) {
						font-size: $font-size-h3;
						margin-bottom: 25px;
					}
				}

				p {
					color: $color-white;
					font-weight: $font-weight-light;
					font-size: 30px;
					line-height: 42px;
					margin-bottom: 0;

					@include mq(tablet-down) {
						font-size: $font-size-med;
						line-height: 30px;
					}
				}

				.btn {
					align-items: center;
					display: flex;
					font-size: 17px;
					font-weight: $font-weight-regular;
					margin-top: 45px;
					max-width: 185px;
					width: 100%;
				}
			}
		}
	}
}
