ul.list-posts {
	@extend %list-default;

	display: inline-flex;
	justify-content: space-between;
	flex-flow: row wrap;
	width: 100%;

	@include mq(mobile) {
		flex-flow: column;
	}


	li {
		width: calc(50% - 25px);
		margin: 0 0 50px;
		background: $color-white;
		position: relative;
		cursor: pointer;
		transition: box-shadow 0.2s ease;

		@include mq(mobile) {
			width: 100%;
			margin: 0 0 25px;
		}


		a {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 99;
			display: block;
		}

		img {
			width: 100%;
			height: auto;
			display: block;
		}

		&:hover {
			box-shadow: 0 0 30px 0 rgba($color-black, 0.25);

			.sub-detail {
				h3 {
					color: $color-primary;
				}
			}
		}

		.sub-detail {
			padding: 45px 40px;
			position: relative;

			@include mq(mobile) {
				padding: 35px 20px;
			}


			.sub-category {
				display: block;
				margin: 25px 0 15px 0;
				font-weight: $font-weight-bold;
				color: $color-secondary;
				text-transform: uppercase;
			}

			h3 {
				font-size: $font-size-h2;
				margin: 0 0 20px;
				color: $color-link;
				transition: color 0.2s ease;

				@include mq(mobile) {
					font-size: $font-size-larger;
				}
			}

			.sub-copy {
				width: 95%;
				max-width: 360px;
				line-height: $line-height-large;
				font-size: $font-size-med;
				margin: 0 0 35px;
			}

			.sub-date {
				color: $color-text-light;
				font-weight: $font-weight-regular;
				font-size: $font-size-tiny;

				@include mq(mobile) {
					display: inline-block;
					float: right;
				}
			}
		}
	}
}
