#careers {
	.section-hero {
		@extend %hero-default;
	}

	.section-main-copy {
		@extend %copy-with-testimonials;
	}
}

#careersDetail {
	.section-main {
		padding: 85px 0;

		.sub-content {
			@extend %container;

			h1 {
				font-weight: $font-weight-regular;
				margin-bottom: 15px;
			}

			.sub-deadline {
				color: $color-text;
				display: block;
				font-size: $font-size-med;
				font-weight: $font-weight-medium;
				margin-bottom: 50px;
			}

			.sub-copy {
				margin-top: 100px;
				max-width: 815px;
				width: 100%;

				p {
					font-size: $font-size-med;
					font-weight: $font-weight-light;
					line-height: 25px;
				}
			}
		}
	}
}

.section-jobs {
	background-color: #f7f7f7;
	padding: 80px 0;

	@include mq(tablet-down) {
		padding: 40px 0;
	}


	.sub-content {
		@extend %container;

		h2 {
			font-size: $font-size-h1;
			margin-bottom: 50px;

			@include mq(tablet-down) {
				font-size: $font-size-larger;
				margin-bottom: 35px;
			}
		}

		ul.list-jobs {
			@extend %list-default;

			margin-bottom: 0px;

			li {
				align-items: center;
				background-color: $color-white;
				display: flex;
				height: 86px;
				margin-bottom: 5px;
				padding: 0 30px;

				@include mq(tablet-down) {
					align-items: flex-start;
					flex-direction: column;
					min-height: 165px;
					padding: 25px 15px;
				}


				.sub-job-title {
					color: $color-text;
					font-size: $font-size-med-large;
					font-weight: $font-weight-regular;
					margin-right: 120px;
					min-width: 200px;

					@include mq(tablet-down) {
						margin-bottom: 15px;
						margin-right: 0;
					}
				}

				.sub-deadline {
					color: $color-text;
					font-size: $font-size-med-large;
					font-weight: $font-weight-regular;
				}

				.btn {
					@extend %btn-mobile;

					color: $color-black;
					font-weight: $font-weight-regular;
					max-height: 45px;
					margin-left: auto;

					@include mq(tablet-down) {
						margin-left: 0;
						min-height: 36px;
					}
				}
			}
		}

		& > .btn {
			@extend %btn-mobile;

			margin: 0 auto;
			max-width: 300px;

			@include mq(tablet-down) {
				max-width: 283px;
				margin: 0;
			}
		}
	}
}
