html {
	font-size: $font-size-base;

	body {
		line-height: $line-height-base;
		font-weight: $font-weight-regular;
		font-family: $font-standard;
		background: $color-body;
		color: $color-black;
		font-size: $font-size-standard;
		margin: 0;
		overflow-x: hidden;

		// padding-bottom: 280px;

		@include mq(tablet-down) {
			padding-bottom: 0;
		}


		a {
			font-weight: $font-weight-medium !important;
		}

		p a {
			text-decoration: underline;
		}

		strong {
			font-weight: $font-weight-medium;
		}

		a.anchor {
			display: block;
			visibility: hidden;
			position: relative;
			top: -140px; // Usually height of the header
	
		}

		&.no-scroll {
			overflow: hidden;
		}

		section {
			background-color: $color-white;
			position: relative;
			z-index: 99;
		}
	}

	* {
		box-sizing: border-box;
	}
}
