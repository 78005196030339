form {
	.sub-errors p {
		margin: 0 0 5px;
		font-size: $font-size-small;
		color: $color-validation-error;
	}

	.form-element {
		align-items: center;
		display: flex;
		margin: 0 0 20px;
		overflow: hidden;
		position: relative;
		vertical-align: top;
		width: 100%;
		z-index: 1;

		&.error {
			label {
				color: $color-validation-error;
			}
		}

		&.mod-textarea {
			align-items: flex-start;

			label {
				padding-top: 15px;
			}
		}

		input, textarea {
			background: transparent;
			border: 1px solid $color-borders;
			display: block;
			height: 44px;
			padding: 0 15px;
			position: relative;
			font-size: $font-size-standard;
			color: $color-text;
			outline: none;
			width: 100%;

			@include autocomplete-fill {
				background-color: $color-white;
				box-shadow: 0 0 0px 1000px $color-white inset;
				-webkit-text-fill-color: $color-text;
				transition: background-color 5000s ease-in-out 0s;
			}


			@include mq(mobile) {
				font-size: $font-size-med;
			}


			&:focus {
				border-color: $color-primary;

				@include mq(mobile) {
					@include placeholder {
						color: $color-primary;
					}
				}
			}

			@include placeholder {
				color: $color-white;
			}


			@include mq(mobile) {
				padding: 0 10px;

				@include placeholder {
					color: $color-text;
				}
			}
		}

		label {
			color: $color-text;
			font-size: $font-size-med;
			font-weight: $font-weight-light;
			min-width: 110px;
			padding: 0;
			pointer-events: none;
			text-align: left;

			span {
				display: block;

				@include mq(tablet-down) {
					margin-top: 3px;
				}
			}
		}

		textarea {
			height: 115px;
			padding: 22px 15px 4px;
		}
	}

	.btn.btn-primary {
		margin: 25px 0 0 110px;
		padding: 13px;

		@include mq(mobile) {
			padding: 15px 0;
			width: 100%;
			margin: 55px 0 0;
		}
	}
}

input, select, textarea, button, optgroup {
	font-family: $font-standard !important;
}

input[name="Form_Email"] {
	display: none;
	opacity: 0;
	visibility: hidden;
	height: 0;
}
