#blog {
	.section-hero {
		@extend %hero-default;

		.sub-content .sub-copy {
			max-width: 635px;
			width: 100%;

			.btn {
				@include mq(tablet-down) {
					align-items: center;
					font-size: $font-size-standard;
					display: flex;
					height: 37px;
					margin-left: 0;
					min-height: auto;
					margin-top: 50px;
					max-width: 173px;
					width: 100%;
				}
			}
		}
	}

	.section-blog-list {
		background-color: $color-neutral-light;
		padding: 100px 0;

		@include mq(tablet-down) {
			padding: 40px 0 77px;
		}


		.sub-content {
			@extend %container;

			.sub-posts {
				display: flex;
				flex-wrap: wrap;
				margin-top: 15px;

				@include mq(tablet-down) {
					flex-direction: column;
					margin-top: 0;
				}


				.sub-post {
					background-color: $color-white;
					margin-bottom: 35px;
					margin-right: 35px;
					padding: 35px;
					position: relative;
					width: calc(33.333% - 24px);

					@include mq(tablet-down) {
						margin-bottom: 10px;
						margin-right: 0;
						width: 100%;
					}


					&:nth-child(3n), &:last-of-type {
						margin-right: 0;
					}

					& > a {
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;
						z-index: 999;

						&:hover ~ h3 {
							color: $color-primary;
						}

						&:hover ~ .sub-image-wrap .sub-image {
							transform: scale(1.03);
						}
					}

					h5 {
						color: $color-text-light;
						font-size: $font-size-small;
						font-weight: $font-weight-light;
						margin-bottom: 30px;

						@include mq(tablet-down) {
							font-size: $font-size-smaller;
							margin-bottom: 25px;
						}
					}

					h3 {
						font-weight: $font-weight-regular;
						font-size: 27px;
						line-height: 35px;
						padding-right: 55px;
						margin-bottom: 30px;
						transition: color .3s ease;

						@include mq(tablet-down) {
							margin-bottom: 15px;
							padding-right: 0;
						}
					}

					.sub-copy {
						padding-right: 40px;

						@include mq(tablet-down) {
							padding-right: 0;
						}


						p {
							color: $color-text;
							font-size: $font-size-small-med;
							font-weight: $font-weight-light;
							line-height: 24px;

							@include mq(tablet-down) {
								font-size: $font-size-standard;
							}
						}
					}

					.sub-image-wrap {
						height: 205px;
						overflow: hidden;

						@include mq(mobile) {
							height: 190px;
						}


						.sub-image {
							@extend %background-default;

							background-size: 100%;
							background-position: top left;
							height: 100%;
							transition: all .3s ease;
							width: 100%;
						}
					}
				}
			}
		}

		.sub-pagination {
			display: flex;
			justify-content: center;
			margin-top: 50px;
			width: 100%;

			.btn {
				@extend %btn-mobile;

				max-width: 300px;
				width: 100%;

				@include mq(tablet-down) {
					max-width: 173px;
				}
			}
		}
	}
}
