#contact {
	.section-form {
		padding: 90px;

		@include mq(tablet-down) {
			padding: 40px 0;
		}


		.sub-content {
			@extend %container;

			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
			}


			.sub-intro {
				max-width: 385px;
				width: 100%;

				@include mq(tablet-down) {
					max-width: none;
				}


				h1 {
					font-size: 41px;
					font-weight: $font-weight-medium;
					margin-bottom: 30px;

					@include mq(tablet-down) {
						font-size: $font-size-largest;
						margin-bottom: 20px;
					}
				}

				.sub-copy {
					margin-bottom: 30px;

					@include mq(tablet-down) {
						margin-bottom: 30px;
					}


					p {
						color: $color-text;
						font-size: $font-size-med-large;
						font-weight: $font-weight-light;
						line-height: 25px;
						margin-bottom: 0;

						@include mq(tablet-down) {
							font-size: $font-size-med;
							line-height: 27px;
						}
					}
				}

				.sub-details {
					span {
						display: block;
						font-size: $font-size-larger;
						font-weight: $font-weight-regular;
						margin-bottom: 0px;

						@include mq(tablet-down) {
							color: $color-black;
							font-size: $font-size-large;
						}


						a {
							color: $color-black;
							font-weight: $font-weight-regular;
						}

						&.sub-tel a {
							color: $color-black;
						}
					}
				}

				.sub-secondary {
					margin-top: 50px;

					p {
						color: $color-text;
						font-size: $font-size-med-large;
						font-weight: $font-weight-light;
						line-height: 25px;
					}

					.btn {
						margin-top: 55px;
					}
				}
			}

			.sub-form {
				margin-left: 95px;
				max-width: 645px;
				width: 100%;

				@include mq(tablet-down) {
					margin: 50px 0 0;
				}


				form {
					h2 {
						font-size: 22px;
						font-weight: $font-weight-regular;
						margin-bottom: 20px;
					}

					p {
						font-size: $font-size-med;
						font-weight: $font-weight-light;
						line-height: 25px;
						margin-bottom: 67px;
					}

					.sub-errors {
						color: $color-error;
						display: none;
						margin-bottom: 20px;

						.sub-error {
							font-size: $font-size-med;

							strong {
								font-weight: $font-weight-medium;
							}
						}

						span {
							display: block;
							margin-bottom: 3px;
						}
					}

					.btn {
						@extend %btn-mobile;

						@include mq(tablet-down) {
							margin: 40px auto 0;
						}
					}
				}

				.sub-copy-under-form {
					margin: 30px 0;

					p {
						color: $color-text;
						font-size: $font-size-med-large;
						font-weight: $font-weight-light;
						line-height: 25px;
						margin-bottom: 0;

						@include mq(tablet-down) {
							font-size: $font-size-med;
							line-height: 27px;
						}
					}
				}

				.wrap-thanks {
					font-size: $font-size-med-large;
					display: none;
				}
			}
		}
	}

	.section-map {
		display: flex;

		@include mq(tablet-down) {
			background-color: $color-neutral;
			flex-direction: column;
		}


		.sub-locations {
			background-color: $color-neutral;
			display: flex;
			justify-content: center;
			padding-top: 107px;
			max-width: 535px;
			width: 100%;

			@include mq(tablet-down) {
				justify-content: flex-start;
				margin: 0 auto;
				max-width: $container-max-width;
				padding-top: 45px;
				width: $container-width;
			}


			h3 {
				font-size: 35px;
				font-weight: $font-weight-medium;

				@include mq(tablet-down) {
					font-size: $font-size-larger;
					margin-bottom: 25px;
				}
			}

			.list-locations {
				@extend %list-default;

				h4 {
					color: $color-text;
					font-weight: $font-weight-medium;
					margin-bottom: 0;

					@include mq(tablet-down) {
						font-size: $font-size-med;
					}
				}

				p {
					color: $color-text;
					font-size: $font-size-med-large;
					font-weight: $font-weight-light;
					line-height: 28px;

					@include mq(tablet-down) {
						font-size: $font-size-med;
					}
				}
			}
		}

		#map {
			height: 600px;
			width: 100%;
		}
	}
}
