@function highlight-by($color, $percent) {
	@if lightness($color) > 50% {
		@return darken($color, $percent);
	}
	@else {
		@return lighten($color, $percent);
	}
}

// Mixin for placeholder colour change
@mixin placeholder {
	&::-webkit-input-placeholder { @content; }
	&:-moz-placeholder { @content; }
	&::-moz-placeholder { @content; }
	&:-ms-input-placeholder { @content; }
}

// Mixin for autofill colour change
@mixin autocomplete-fill {
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		@content;
	}
}