#gallery {
	.section-hero {
		@extend %hero-default;
	}

	.section-gallery {
		padding: 100px 0;

		.sub-content {
			@extend %container;

			.list-gallery-alt {
				@extend %list-default;

				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;

				& > li {
					cursor: pointer;
					position: relative;
					margin-bottom: 20px;
					margin-right: 20px;
					max-height: 319px;
					width: calc(100% / 4 - 15px);

					@include mq(tablet-down) {
						width: calc(100% / 3 - 15px);
					}

					@include mq(mobile) {
						width: calc(100% / 2 - 15px);
					}

					&:after {
						content: "";
						display: block;
						padding-top: 100%;
					}

					@media (max-width: 2500px) and (min-width: $mq-tablet-size) {
						&:nth-child(4n) {
							margin-right: 0;
						}
					}
					
					&:nth-child(3n) {
						@include mq(tablet) {
							margin-right: 0;
						}
					}
					
					&:nth-child(2n) {
						@include mq(mobile) {
							margin-right: 0;
						}
						
					}
					

					&:hover {
						.caption {
							opacity: 1;
						}
					}

					.sub-image {
						@extend %image-cover;

						background-size: cover;
						height: 100%;
					}

					.caption {
						background: rgba($color-black, 0.5);
						bottom: 0;
						opacity: 0;
						padding: 15px;
						position: absolute;
						transition: opacity ease 0.3s;

						p {
							color: $color-white;
							text-align: center;

							&:only-child, &:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}

	.modal-gallery {

		.modal-close {
			right: 40px;
			top: 40px;
		}

		.sub-gallery {
			@include mq(tablet-down) {
				max-width: 850px;
				width: 100%;
			}
			
			.slick-track {
				display: flex;

				.slick-slide {
					align-self: center;
					display: flex;
					justify-content: center;
				}
			}

			ul.list-gallery li {
				img {
					margin: 0 auto;
					max-height: 650px;
					width: auto;

					@include mq(tablet-down) {
						margin: 0 auto;
						max-width: 100%;
					}

					@include mq(mobile) {
						min-width: auto;
					}
					
					
				}

				.caption {
					margin-top: 20px;
					
					p {
						color: $color-white;
						font-size: $font-size-large;
						text-align: center;

						@include mq(mobile) {
							font-size: $font-size-med;
						}
						
					}
				}
			}

			.sub-gallery-nav {
				margin-top: -50px;

				@include mq(tablet-down) {
					
				}
				

				.sub-prev {
					margin-left: -30px;
				}

				.sub-next {
					margin-right: -30px;
				}
			}
		}
	}

}