#portal {
	.section-hero {
		@extend %hero-default;

		.sub-content .sub-copy {
			@include mq(tablet-down) {
				padding-right: 50px;
			}
		}

		.btn {
			@extend %btn-mobile;

			@include mq(tablet-down) {
				max-width: 125px;
			}
		}
	}

	.section-information {
		padding: 95px 0 125px;

		@include mq(tablet-down) {
			padding: 38px 0;
		}


		.sub-content {
			@extend %container;

			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
			}


			.sub-copy {
				flex: 1;

				p {
					font-size: $font-size-med;
					font-weight: $font-weight-medium;
					line-height: 32px;

					@include mq(tablet-down) {
						font-size: $font-size-med;
						line-height: 27px;
					}
				}
			}

			.sub-image {
				margin-left: 170px;

				@include mq(tablet-down) {
					margin-left: auto;
					text-align: center;

					img {
						margin: auto 0;
						max-width: 70%;
					}
				}
			}
		}
	}

	.section-feat-ben {
		background-color: #f7f7f7;
		padding: 90px 0 130px;

		@include mq(tablet-down) {
			padding: 53px 0 45px;
		}


		.sub-content {
			@extend %container;

			& > h2 {
				font-size: $font-size-h1;
				margin-bottom: 20px;

				@include mq(tablet-down) {
					font-size: $font-size-larger;
				}
			}

			.sub-copy {
				max-width: 740px;
				width: 100%;

				p {
					font-size: $font-size-med-large;

					@include mq(tablet-down) {
						font-size: $font-size-med;
						line-height: 25px;
					}
				}
			}

			.list-features {
				@extend %list-default;

				display: flex;
				flex-wrap: wrap;
				margin-top: 60px;

				@include mq(tablet-down) {
					margin-top: 30px;
				}


				& > li {
					background-color: $color-white;
					margin-right: 30px;
					margin-bottom: 30px;
					padding: 28px;
					width: calc((100% / 4) - 23px);

					@include mq(tablet-down) {
						margin-bottom: 8px;
						margin-right: 0;
						padding: 20px 15px;
						width: 100%;
					}


					&:nth-child(4n) {
						margin-right: 0;
					}

					h4 {
						align-items: center;
						display: flex;
						font-size: $font-size-med-large;
						margin-bottom: 25px;

						@include mq(tablet-down) {
							margin-bottom: 18px;
						}


						img {
							margin-right: 26px;
							max-width: 26px;
						}
					}

					.sub-copy {
						padding-right: 10px;

						p {
							font-size: $font-size-small;
							line-height: 24px;

							&:only-child, &:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}

	.section-demo {
		@extend %background-default;

		align-items: center;
		display: flex;
		height: 500px;

		@include mq(tablet-down) {
			height: auto;
			padding: 48px 0 71px;
		}


		.sub-content {
			@extend %container;

			.sub-copy {
				max-width: 450px;
				width: 100%;

				h4 {
					color: $color-white;
					font-size: $font-size-h3;
					font-weight: $font-weight-bold;
					margin-bottom: 25px;

					@include mq(tablet-down) {
						font-size: 24px;
					}
				}

				p {
					color: $color-white;
					font-size: $font-size-med;
					font-weight: $font-weight-medium;
					line-height: 32px;

					@include mq(tablet-down) {
						font-size: $font-size-standard;
						line-height: 25px;
					}
				}

				.btn {
					@extend %btn-mobile;

					font-size: $font-size-small-med;
					max-width: 225px;
					width: 100%;

					@include mq(tablet-down) {
						max-width: 173px;
					}
				}
			}
		}
	}
}
