#technologies {
	.section-hero {
		@extend %hero-default;

		.sub-content .sub-copy {
			max-width: 655px;
		}
	}

	.section-main-copy {
		@extend %copy-with-testimonials;

		.sub-content .sub-copy {
			p {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		h3 {
			font-size: 30px !important;
			margin-bottom: 25px;
		}

		.sub-content .sub-testimonials .list-testimonials-tech blockquote p {
			font-size: $font-size-med;
			font-weight: $font-weight-light;
			line-height: 32px;
		}
	}
}

.section-sectors {
	background-color: #f7f7f7;
	padding: 82px 0;

	@include mq(tablet-down) {
		padding: 40px 0 55px;
	}


	.sub-content {
		@extend %container;

		h2 {
			font-size: $font-size-h1;
			margin-bottom: 55px;

			@include mq(tablet-down) {
				font-size: $font-size-larger;
			}
		}

		.list-sectors {
			@extend %list-default;

			flex-wrap: wrap;
			display: flex;
			margin-top: 25px;

			@include mq(tablet-down) {
				flex-direction: column;
				margin-top: 0;
			}


			& > li {
				background-color: $color-white;
				display: flex;
				flex-direction: column;
				margin-bottom: 38px;
				margin-right: 26px;
				padding: 27px;
				width: calc(33.333% - 18px);

				@include mq(tablet-down) {
					margin-bottom: 15px;
					padding: 25px 15px;
					width: 100%;
				}


				&:nth-child(3n), &:last-child {
					margin-right: 0;
				}

				h3 {
					font-size: $font-size-larger;
					margin-bottom: 20px;

					@include mq(tablet-down) {
						font-size: $font-size-med-large;
						margin-bottom: 10px;
					}
				}

				.sub-copy {
					p {
						color: $color-text;
						font-size: $font-size-small-med;
						font-weight: $font-weight-light;
						line-height: 24px;
						margin-bottom: 20px;
					}

					ul {
						color: $color-text;
						font-size: $font-size-small-med;
						font-weight: $font-weight-light;
						line-height: 24px;
						list-style: disc;
					}
				}

				.sub-image {
					@extend %background-default;

					height: 150px;
					margin-top: auto;
				}
			}
		}
	}
}
