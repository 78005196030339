// AOS custom properties
[data-aos] {
	body[data-aos-easing="gentle"] &,
	&[data-aos][data-aos-easing="gentle"] {
		transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
	}
}

@keyframes input-fade {
	50% {
		opacity: 0;
		transform: translate3d(1em, 0, 0);
	}

	51% {
		opacity: 0;
		transform: translate3d(-1em, -110%, 0);
	}

	100% {
		color: rgba($color-text, 0.55);
		font-size: $font-size-standard;
		font-weight: $font-weight-light;
		opacity: 1;
		transform: translate3d(0, -100%, 0);
	}
}
